import React, { Component, useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import $ from "jquery";
import { withCookies, useCookies } from 'react-cookie';
import { privacyPolicyLabel } from '../../model/PageLabels';

import { useMatomo } from '@datapunt/matomo-tracker-react'

const PrivacyPolicy = props => 
{
	const { pushInstruction } = useMatomo();
	// const matomoInstance = props.matomo;
	const [cookies, setCookie, removeCookie] = useCookies([]);
	console.log("cookies",cookies)

	const [textBox, setTextBox] = useState(props.literals.L101074);
	const [textCheck, setTextCheck] = useState(props.literals.L101075);
	const [checked, setChecked] = useState(true);

	useEffect(() => {
		document.title = privacyPolicyLabel
		
		var check = $('#box1 > div:nth-child(2) > input[type=checkbox]')[0];
		
		console.log("cookies.mtm_cookie_consent",cookies.mtm_cookie_consent);
		console.log("cookies.mtm_consent_removed",cookies.mtm_consent_removed);

        if(check != null){
			
			if(cookies.mtm_cookie_consent != undefined){
				setChecked(true);
				setTextBox(props.literals.L101061);
				setTextCheck(props.literals.L101062);
            }else{
				setChecked(false);
				setTextBox(props.literals.L101074);
				setTextCheck(props.literals.L101075);
            }
        }
	}, []);

	function toogleMatomo (val) {
        if(val==true) {
			pushInstruction("rememberCookieConsentGiven",360);
            pushInstruction("setConsentGiven");
			pushInstruction("setCookieConsentGiven");

			if(cookies.mtm_consent_removed != undefined){
				removeCookie("mtm_consent_removed");
			}
        } else {
            pushInstruction("forgetConsentGiven");
			pushInstruction("forgetCookieConsentGiven");
        }
    }

	const checkCookies = event => {
		console.log('event.target.checked',event.target.checked);
		if(event.target.checked){
			toogleMatomo(true);
			setChecked(!checked);
			setTextBox(props.literals.L101061);
			setTextCheck(props.literals.L101062);
		}else{
			toogleMatomo(false);
			setChecked(!checked);
			setTextBox(props.literals.L101074);
			setTextCheck(props.literals.L101075);
		}
	}

	return(
		<div>
			{/* <!-- Title --> */}
			<h1 className="title-section second-color ng-binding text-center">{props.literals.L101045}</h1>
			<div className="container legal--notice--block">
				<h2 className="title-section main-color">{props.literals.L101046}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101047)}
					{/* <p>Andrew Smith, Head of Communication and Promotion Unit</p><p>information (at) osha (dot) europa (dot) eu</p> */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101048}</h2>
				<div>
					{props.literals.L101049}
					{/* For the purpose of producing anonymous statistics on the use of this website, log files are created for each access to the website, containing the following aggregated data: total number of visits, countries of the users, duration of sessions, and the path followed by the users during the sessions. */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101050}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101051)}
					{/* <p>Those log files contain the following information:</p><ul><li>The user’s IP address</li><li>The date and time when the user’s request to access the website reached the web server</li><li>The requested URL</li><li>The HTTP return code served to the requester (the user)</li><li>The requester’s processing time</li><li>The requester’s user agent string.</li></ul> */}
				</div>
				{/* <!--<div data-ng-bind-html="i18n.ulTypeData" className="list--tag--block"></div>--> */}
				
				<h2 className="title-section main-color">{props.literals.L101052}</h2>
				<div>
					{props.literals.L101053}
					{/* Regulation (EU) 2019/126 of the European Parliament and of the Council of 16 January 2019 establishing the European Agency for Safety and Health at Work (EU-OSHA), and repealing Council Regulation (EC) No 2062/94. */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101054}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101055)}
					{/* <p>The processing is based on <a href="https://eur-lex.europa.eu/eli/reg/2018/213/oj" target="_blank">Article 5(a) of Regulation (EU) 2018/1725</a>&nbsp;of the European Parliament and of the Council of 23 October 2018 on the protection of natural persons with regard to the processing of personal data by the Union institutions, bodies, offices and agencies and on the free movement of such data (hereinafter the Regulation).</p> */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101056}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101057)}
					{/* <p>Access to the personal data is granted on the basis of the role and responsibilities of the subjects involved (“need to know” principle):</p><ul><li>Duly appointed EU-OSHA staff</li><li>External provider hosting EU-OSHA’s server</li></ul> */}
				</div>
				{/* <!--<div data-ng-bind-html="i18n.DulyAppointed"></div> */}
				{/* <div data-ng-bind-html="i18n.AllTheRecipients"></div>--> */}
				
				<h2 className="title-section main-color">{props.literals.L101058}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101059)}
					{/* <p>This website will set temporary session cookies whenever you visit the site. These cookies are needed to allow the session, but they do not contain personal data.</p><p>This website collects web statistics with&nbsp;<strong>Matomo</strong>, that is entirely hosted in EU-OSHA’s servers, located in the European Union. Matomo will store cookies in your computer, but they do not contain personal data. The IP address is received by Matomo for geographical statistic purpose only (country, region and city).</p> */}
				</div>
				<div>
					{props.literals.L101060}
					{/* If you do not want EU-OSHA to track your activity through Matomo, you can opt-out from Matomo by clicking in the box below. */}
				</div>

				<div className="box">
					<div id='box1'>
						<div data-ng-bind-html="textBox">{ReactHtmlParser(textBox)}</div>
						<div>
						<input type='checkbox' onChange={checkCookies} checked={checked}/> <strong>{ReactHtmlParser(textCheck)}</strong>
						</div>
					</div>
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101063}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101064)}
					{/* <p>Data subjects have the right to request from the controller access to and rectification or erasure of personal data or restriction of processing concerning the data subject or, where applicable, the right to object to processing or the right to withdraw consent at any time without affecting the lawfulness of processing based on consent before its withdrawal (Articles 17, 18, 19, 20, 22, 23 and 24 of the Regulation).</p><p>Any requests to exercise one of those rights should be directed per email to the organisational part of the Agency entrusted with the processing operation as indicated in this privacy statement, including in the subject the words “data protection”.</p><p>Data subjects’ rights can be restricted only in the cases foreseen in Art 25 of the Regulation.</p> */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101065}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101066)}
					{/* <p>The information contained in the log files are kept for 2 years in EU-OSHA’s server hosted by an external provider located in the European Union.</p><p>Data for the creation of anonymous statistical reports will be kept for a longer period.</p> */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101067}</h2>
				<div>
					{props.literals.L101068}
					{/* We take appropriate security measures to protect against unauthorised access to or unauthorised alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption of communication and physical security measures to guard against unauthorised access to systems where we store personal data. */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101069}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101070)}
					{/* <p>For any further information regarding the handling of their personal data, data subjects can address their request to EU-OSHA Data Protection Officer at <a href="mailto:dpo@osha.europa.eu" target="_blank">dpo@osha.europa.eu.</a></p> */}
				</div>
				
				<h2 className="title-section main-color">{props.literals.L101071}</h2>
				<div>
					{ReactHtmlParser(props.literals.L101072)}
					{/* <p>Data subjects are entitled to make recourse to the European Data Protection Supervisor: <a href="http://www.edps.europa.eu/" target="_blank">http://www.edps.europa.eu</a> , should they consider that the processing operations do not comply with the Regulation.</p> */}
				</div>

				<h2 className="title-section main-color">{props.literals.L692}</h2>
				<div>
					{ReactHtmlParser(props.literals.L693)}
					{/* <p>Date of access to the website.</p><p><em>Privacy statement last updated: 6/20/2019 4:21:38 PM</em></p> */}
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state){
    const {literals} = state;	
    return {
        literals
    }
}

export default connect(mapStateToProps, null)(PrivacyPolicy);

// export default PrivacyPolicy;